import React, { useState, useEffect, useContext } from 'react';
import { CircuitsChoiceWrapper } from 'ToolboxComponents/webapp/pages/circuits-choice-view/circuits-choice-view.styles';
import { ThemeContext } from 'ToolboxUtils/web/context/context';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';
import Anchor from 'ToolboxComponents/commons/anchors/anchor/anchor';
import ImageResize from 'ToolboxComponents/utils/images/image-resize/image-resize';
import PropTypes from 'prop-types';
import slugify from 'url-slug';
import Text from 'ToolboxComponents/commons/texts/text/text';

const CircuitsChoiceView = ({ circuits, title }) => {
  const [theme] = useContext(ThemeContext).usePath();
  const [isMobile, setIsMobile] = useState(null);

  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);

  return (
    <CircuitsChoiceWrapper theme={theme}>
      <h1 className='title'>{title ? <span>{title}</span> : <Text path='page.circuits.title' />}</h1>
      {circuits && circuits.length > 0 &&
        <div className='circuits'>
          {circuits.filter(x => !!x).map(circuit => (
            <div key={circuit.id} className= {`${isMobile ? 'circuit': 'circuit'}`}>
              {isMobile ?
                <ImageResize src={circuit.photo}/> : 
                <div className='circuit-photo'>
                  <ImageResize src={circuit.photo}/>
                </div> 
              }          
              <div className='texts'>
                <div className='description'>
                  <span className='circuit-title'>{circuit.title}</span>
                  <span className='circuit-teaser'>{circuit.teaser}</span>
                </div>
                  <Anchor code={circuit.button.link.page} pageParams={circuit.button.link.pageParams} className='link'>
                    <Text path={circuit.button.text} data={circuit.button.data} />
                  </Anchor>
              </div>
            </div>
          ))}
        </div>
      }
    </CircuitsChoiceWrapper>
  );
};

CircuitsChoiceView.propTypes = {
  circuits: PropTypes.array.isRequired,
};

export default CircuitsChoiceView;
