import styled from 'styled-components';
import { isMobileBreakpoint } from 'ToolboxUtils/web/is-mobile-view';

export const CircuitsChoiceWrapper = styled.div`
  .title {
    margin-bottom: 40px;
    margin-top: 20px;
    font-family: ${props => props.theme.font.primaryFont.name};
    font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)};
    text-align: center;
    color: rgb(33, 37, 41);
  }
  .circuit-photo {
    height: 230px;
    width: 100%;
  }
  .circuits {
    background: rgba(211, 208, 208, 0.1);
    display: flex;
    flex-direction: row;
    padding: 30px 10px;
    margin-bottom: 70px;
    .circuit {
      background: ${props => props.theme.color.primaryColor};
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
      margin: 0 15px;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
      .texts {
        color: white;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        .description {
          display: flex;
          flex-direction: column;
          width: 100%;
          .circuit-title {
            font-size: ${props => `${(22 * props.theme.font.primaryFont.scaling) / 100}px`};
            font-weight: ${props => parseInt(props.theme.font.primaryFont.weight) + 300};
            padding: 20px 14px 14px 14px;
            text-align: center;
            text-transform: uppercase;
          }
          .circuit-teaser {
            font-size: ${props => `${(16 * props.theme.font.primaryFont.scaling) / 100}px`};
            padding: 0 14px 14px 14px;
            text-align: center;
          }
        }
        .link {
          background: #3d3d3d;
          border-radius: 16px;
          color: white;
          display: inline-block;
          font-weight: ${props => parseInt(props.theme.font.primaryFont.weight) + 300};
          margin: auto;
          margin-bottom: 24px;
          margin-top: 8px;
          padding: 6px;
          text-align: center;
          width: 80%;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  @media (max-width: ${isMobileBreakpoint}px) {
    .circuits {
      flex-direction: column;
      margin-bottom: 20px;
      padding: 20px 0 0 0;
    }
    .title {
      font-size: ${props => `${(22 * props.theme.font.primaryFont.scaling) / 100}px`};
      padding: 0 15px;
      margin-bottom: 20px;
    }
    .circuits {
      .circuit {
        flex-direction: row;
        height: 270px;
        margin-bottom: 20px;
        img{
          width: 50%;
        }
        .texts {
          .circuit-title {
            font-size: ${props => `${(15 * props.theme.font.primaryFont.scaling) / 100}px !important`};
          }
          .circuit-teaser {
            font-size: ${props => `${(14 * props.theme.font.primaryFont.scaling) / 100}px !important`};
          }
          .link {
            font-size: ${props => `${(13 * props.theme.font.primaryFont.scaling) / 100}px !important`};
          }
        }
      }
    }
  }
`;
